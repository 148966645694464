import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  ChakraBaseProvider,
  extendTheme,
  theme as chakraTheme,
} from '@chakra-ui/react'


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const {Modal, Button} = chakraTheme.components

const theme = extendTheme({
  components: {
    Modal,
    Button
  },
  colors: {
    dark: "#151515",
    resetred: "#E23030",
    darkborder: "#252525"
  }
})

root.render(
  <ChakraBaseProvider theme={theme}>
    <App />
  </ChakraBaseProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
